import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  HelpCircle,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import DetailSection from "./DetailSection";
import { BusinessData, HomeLoanData, PersonalData, PropertyData, WorkingCapitalData } from "../../JsonData";
import Testimonials from "./testimonials";
import BusinessLoan from "./BusinessLoan";
import CityShowCase from "./CityShowCase";
import Loader from "../../Loader/Loader";
import PartnerBanks from "./PartnerBanks";
import CustomerService from "../../../CustomerService/CustomerService";

const tenureOptions = [5, 10, 15, 20, 25, 30, 32];


export default function HomeLoan({pageType}) {
  const [data, setData] = useState(null);
  const [loanAmount, setLoanAmount] = useState(5000000);
  const [tenure, setTenure] = useState(25);
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const calculateEMI = (principal, years, ratePerAnnum) => {
    const monthlyRate = ratePerAnnum / (12 * 100);
    const numberOfPayments = years * 12;
    const emi =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) /
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    return Math.round(emi);
  };

  useEffect(() => {
    const calculatedEMI = calculateEMI(loanAmount, tenure, 8.5);
    setEmi(calculatedEMI);
    setTotalAmount(calculatedEMI * tenure * 12);
    setTotalInterest(calculatedEMI * tenure * 12 - loanAmount);
  }, [loanAmount, tenure]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
      .format(amount)
      .replace("₹", "₹ ");
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % data?.features?.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + data?.features?.length) % data?.features?.length
    );
  };
  useEffect(() => {
    // This is where you pass the JSON data based on the page type
    const fetchData = () => {
      if (pageType === 'homeLoan') {
        setData(HomeLoanData);
      } else if (pageType === 'personalLoan') {
        setData(PersonalData);
      }else if (pageType === 'propertyLoan') {
        setData(PropertyData);
      }else if (pageType === 'buinessLoan') {
        setData(BusinessData);
      } else if (pageType === 'workingLoan') {
        setData(WorkingCapitalData);
      }else {
        setData(null);
      }
    };

    fetchData();
  }, [pageType]);
  return (
    <>
    
    
        <div className="min-h-screen  pt-12 relative overflow-hidden">
      {/* Animated gradient strips */}
      {!data ? (
        <Loader/>
      ) : (
        <>
      <motion.div
        className="absolute inset-0 z-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="relative w-full h-full overflow-hidden">
          <div className="absolute top-2 left-0 w-full h-1 bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 bg-[length:200%_100%] blur-md opacity-90 animate-wave"></div>
          <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-cyan-300 via-blue-400 to-cyan-500 bg-[length:200%_100%] animate-wave"></div>
          <div className="absolute bottom-0 left-0 w-full h-2 bg-gradient-to-r from-cyan-500 via-blue-700 to-cyan-700 bg-[length:200%_100%] animate-wave"></div>
          <div className="absolute bottom-10 left-0 w-full h-1 bg-gradient-to-r from-cyan-600 via-purple-600 to-cyan-800 bg-[length:200%_100%] blur-lg animate-wave"></div>
        </div>
      </motion.div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.h1
          className="text-4xl md:text-5xl font-bold text-center mb-12 text-[var(--loanlift-primary-bg)]"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {data?.title[0]}  <span className="text-[var(--lightblue-color)]">{data?.title[1]}</span>
        </motion.h1>
        <div className="flex flex-col lg:flex-row gap-4">
          {/* Left Sidebar with Carousel */}
          <div className="lg:w-1/3">
            <div className="relative h-[400px] lg:h-[600px] overflow-hidden rounded-2xl shadow-xl">
              <AnimatePresence initial={false} custom={currentSlide}>
                {data?.features?.map((item, index) => (
                  <motion.div
                    key={index}
                    custom={index}
                    variants={{
                      enter: (direction) => ({
                        x: direction > 0 ? "100%" : "-100%",
                        opacity: 0,
                      }),
                      center: {
                        x: 0,
                        opacity: 1,
                        transition: {
                          x: { type: "spring", stiffness: 300, damping: 30 },
                          opacity: { duration: 0.5 },
                        },
                      },
                      exit: (direction) => ({
                        x: direction < 0 ? "100%" : "-100%",
                        opacity: 0,
                        transition: {
                          x: { type: "spring", stiffness: 300, damping: 30 },
                          opacity: { duration: 0.5 },
                        },
                      }),
                    }}
                    initial="enter"
                    animate={index === currentSlide ? "center" : "exit"}
                    exit="exit"
                    className="absolute inset-0 "
                    style={{
                      backgroundImage: `url(${item.bg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute inset-0 bg-gradient-to-b from-cyan-600/60 to-cyan-900/80"></div>
                    <motion.div
                      className="relative h-full w-full p-6 flex flex-col justify-center items-center text-center text-white"
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.5 }}
                    >
                      <motion.div
                        className="text-2xl font-semibold mb-2"
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.3, duration: 0.5 }}
                      >
                        {item.title}
                      </motion.div>
                      <motion.div
                        className="text-4xl font-bold mb-4"
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                      >
                        {item.value}
                      </motion.div>
                    </motion.div>
                  </motion.div>
                ))}
              </AnimatePresence>
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {data?.features.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentSlide(index)}
                    className={`w-2 h-2 rounded-full transition-colors ${
                      currentSlide === index ? "bg-white" : "bg-white/50"
                    }`}
                  />
                ))}
              </div>
              <button
                onClick={prevSlide}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/10 hover:bg-white/20 rounded-full p-2 transition-colors"
              >
                <ChevronLeft className="w-6 h-6 text-white" />
              </button>
              <button
                onClick={nextSlide}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/10 hover:bg-white/20 rounded-full p-2 transition-colors"
              >
                <ChevronRight className="w-6 h-6 text-white" />
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:w-1/2">
            <motion.div
              className="bg-white shadow-xl rounded-2xl p-8 relative overflow-hidden"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-cyan-50 via-transparent to-cyan-50 pointer-events-none"></div>
              <div className="relative z-10">
                <h2 className="text-3xl font-bold text-gray-800 mb-6">
                  Calculate Your EMI
                </h2>
                <p className="text-gray-600 mb-8 text-lg">
                  Interest rates starting 8.50%* p.a. | Loan up to Rs. 15 cr* |
                  Tenure up to 32 years*
                </p>

                <div className="space-y-8">
                  {/* Loan Amount Input */}
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                  >
                    <label className="block text-gray-700 mb-2 text-lg font-semibold">
                      Enter loan amount
                    </label>
                    <div className="relative">
                      <span className="absolute left-3 top-1/3 -translate-y-1/2 text-cyan-500 text-xl">
                        ₹
                      </span>
                      <input
                        type="number"
                        value={loanAmount}
                        onChange={(e) => setLoanAmount(Number(e.target.value))}
                        className="w-full pl-10 pr-4 py-3 border-2 border-cyan-300 rounded-xl focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 text-xl transition-all duration-300 ease-in-out "
                        placeholder="Enter amount"
                      />
                      <div className="text-sm text-gray-500 mt-2">
                        (Rs. 4 lakh - Rs. 15 crore*)
                      </div>
                    </div>
                  </motion.div>

                  {/* Tenure Selection */}
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                  >
                    <label className="block text-gray-700 mb-2 text-lg font-semibold">
                      Selected loan tenure: {tenure} years
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {tenureOptions.map((year) => (
                        <button
                          key={year}
                          onClick={() => setTenure(year)}
                          className={`px-4 py-2 rounded-xl border-2 transition-all duration-300 ease-in-out ${
                            tenure === year
                              ? "bg-cyan-500 text-white border-cyan-500 shadow-lg"
                              : "bg-white text-gray-700 border-cyan-200 hover:border-cyan-500 hover:shadow"
                          }`}
                        >
                          {year}
                        </button>
                      ))}
                    </div>
                  </motion.div>

                  {/* EMI Display */}
                  <motion.div
                    className="bg-gradient-to-br from-cyan-100 to-cyan-50 p-6 rounded-xl shadow-inner"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                  >
                    <div className="text-gray-700 mb-2 text-lg">
                      EMI for {tenure} years at 8.50% p.a.
                    </div>
                    <div className=" sm:text-3xl lg:text-5xl   font-bold text-gray-900 mb-4">
                      ₹{formatCurrency(emi).split("₹ ")[1]}
                    </div>

                    {/* Progress Bar */}
                    <div className="relative h-4 bg-gray-200 rounded-full overflow-hidden mb-4">
                      <motion.div
                        className="absolute h-full bg-cyan-500"
                        initial={{ width: 0 }}
                        animate={{
                          width: `${(loanAmount / totalAmount) * 100}%`,
                        }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                      />
                      <motion.div
                        className="absolute h-full bg-yellow-400"
                        initial={{ width: 0 }}
                        animate={{
                          width: `${(totalInterest / totalAmount) * 100}%`,
                          left: `${(loanAmount / totalAmount) * 100}%`,
                        }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                      />
                    </div>

                    <div className="flex sm:flex-col lg:flex-row justify-between items-center text-sm w-full">
                      <div className="p-2">
                        <div className="text-gray-600">Principal amount</div>
                        <div className="font-semibold text-lg">
                          ₹{formatCurrency(loanAmount).split("₹ ")[1]}
                        </div>
                      </div>
                      <div className="p-2">
                        <div className="text-gray-600">Total Interest</div>
                        <div className="font-semibold text-lg">
                          ₹{formatCurrency(totalInterest).split("₹ ")[1]}
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 text-gray-600 text-lg">
                      Total Amount ₹{formatCurrency(totalAmount).split("₹ ")[1]}
                    </div>
                  </motion.div>

                  {/* Features */}
                  <motion.div
                    className="grid grid-cols-2 md:grid-cols-5 gap-4 mt-8"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                  >
                    {data?.calculatorfeature?.map((feature, index) => (
                      <motion.div
                        key={index}
                        className="text-center"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <div className="bg-cyan-100  rounded-full p-4 w-16 h-16 mx-auto mb-2 transition-all duration-300 ease-in-out hover:bg-cyan-200">
                          <feature.icon className="w-8 h-8 text-cyan-600" />
                        </div>
                        <div className="text-sm font-medium">
                          {feature.text}
                        </div>
                      </motion.div>
                    ))}
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Right Summary Panel */}
          <div className="lg:w-1/3">
            <motion.div
              className="bg-white shadow-xl rounded-2xl p-6 relative overflow-hidden"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="absolute inset-0 bg-gradient-to-tl from-cyan-50 via-transparent to-cyan-50 pointer-events-none"></div>
              <div className="relative z-10">
                <h2 className="text-2xl font-bold mb-4">Loan summary</h2>
                <p className="text-sm text-gray-600 mb-4">
                  This summary is based on the details provided by you. It may
                  get updated basis your final loan eligibility.{" "}
                  <span className="text-cyan-500 cursor-pointer hover:underline">
                    T&C
                  </span>
                </p>

                <div className="space-y-4">
                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                  >
                    <div className="flex justify-between text-sm mb-1">
                      <span>Requested loan amount</span>
                      <span className="font-semibold">
                        ₹{formatCurrency(loanAmount).split("₹ ")[1]}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-1">
                      <span>Total loan amount (A)</span>
                      <span className="font-semibold">
                        ₹{formatCurrency(loanAmount).split("₹ ")[1]}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-1">
                      <div className="flex items-center">
                        <span>Processing fee</span>
                        <HelpCircle className="w-4 h-4 ml-1 text-gray-400 cursor-pointer" />
                      </div>
                      <span className="font-semibold">₹15,000</span>
                    </div>
                    <div className="flex justify-between text-sm mb-1">
                      <div className="flex items-center">
                        <span>Applicable govt. charges</span>
                        <HelpCircle className="w-4 h-4 ml-1 text-gray-400 cursor-pointer" />
                      </div>
                      <span className="font-semibold">As per actual</span>
                    </div>
                    <div className="flex justify-between text-sm mb-1">
                      <span>Applicable charges (B)</span>
                      <span className="font-semibold">₹15,000</span>
                    </div>
                  </motion.div>

                  <motion.div
                    className="bg-cyan-50 p-4 rounded-xl"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                  >
                    <div className="flex justify-between font-semibold">
                      <span>Amount to be credited in your account (A-B)*</span>
                      <span>
                        ₹{formatCurrency(loanAmount - 15000).split("₹ ")[1]}
                      </span>
                    </div>
                  </motion.div>

                  <motion.div
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                  >
                    <h3 className="font-semibold mb-2">
                      Your summary based on the total loan amount
                    </h3>
                    <div className="space-y-2">
                      <div className="flex justify-between text-sm">
                        <span>Loan amount</span>
                        <span className="font-semibold">
                          ₹{formatCurrency(loanAmount).split("₹ ")[1]}*
                        </span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span>Tenure</span>
                        <span className="font-semibold">{tenure} years</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span>Annualised rate of interest</span>
                        <span className="font-semibold">8.50%</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span>EMI</span>
                        <span className="font-semibold">
                          ₹{formatCurrency(emi).split("₹ ")[1]}*
                        </span>
                      </div>
                    </div>
                  </motion.div>

                  <motion.div
                    className="text-sm text-gray-600"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                  >
                    <p className="font-semibold">Please note:</p>
                    <p>**Subject to change basis final eligibility.</p>
                    <p>
                      **Rate of interest may vary based on RBI and/or BHFL
                      policies/ guidelines. For detailed FRR,{" "}
                      <span className="text-cyan-500 cursor-pointer hover:underline">
                        click here
                      </span>
                      .
                    </p>
                  </motion.div>

                  <motion.button
                    className="w-full bg-gradient-to-r from-cyan-500 to-cyan-600 text-white py-3 rounded-full font-semibold hover:from-cyan-600 hover:to-cyan-700 transition-colors shadow-lg"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                  >
                    APPLY NOW ✔︎
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <motion.div
          className="mt-12 text-sm text-gray-600"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}
        >
          {/* <p className="mb-4">
            <span className="font-semibold text-lg">
              Trusted by 3 lakh+ customers
            </span>
          </p>
          <p className="text-justify">
            BluBucks offers a home loan of up to Rs. 15 crore* based on your
            eligibility, to buy your dream home with ease. With interest rates
            starting from 8.50%* p.a., you can get a home loan at an EMI of just
            Rs. 759/lakh*. A housing loan with us comes with several benefits,
            like a flexible tenure of 32 years, no foreclosure fee, hassle-free
            application along with 5,000+ approved projects for a quick loan
            process.
          </p> */}
          <DetailSection data={data}/>
          <Testimonials testimonials={data?.testimonials}/>
          {/* <BusinessLoan/> */}
        </motion.div>
      </div>
        </>
      )}
    </div>

          <CityShowCase/>
    <CustomerService/>
      <PartnerBanks/>
    </>

  );
}
