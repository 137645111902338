import React from 'react'
import { motion } from 'framer-motion'
import { Play, ArrowRight, Percent, Clock, GraduationCap, Building2, ChevronRight,ShieldCheck, Smile, TrendingUp, Globe } from 'lucide-react'
import Aboutimage1 from './image/about-1-1.jpg';
import Aboutimage2 from './image/about-1-2.jpg';
import Aboutimage3 from './image/why-choose-1-1.jpg';
import Aboutimage4 from './image/why-choose-1-2.jpg';
import PartnerBanks from '../ServicePages/HomeLoan/PartnerBanks';

import About1 from './image/ab1.png';
import About2 from './image/ab2.png';
import why1 from './image/why1.png';
import why2 from './image/why2.png';
import why3 from './image/why3.png';
import journey from './image/our journey.png';
import whatweoffer from './image/what we offer.png';
import promise from './image/our promise.png';
import CustomerService from '../../CustomerService/CustomerService';
import founder1 from './image/founder (1).png';
import founder2 from './image/founder (2).png';
import founder3 from './image/founder (3).png';

const Aboutus = () => {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const staggerChildren = {
    animate: { transition: { staggerChildren: 0.1 } }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <motion.div 
        className="relative h-[400px] bg-[var(--loanlift-primary-bg)] flex items-center justify-center overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Animated background dots */}
        <motion.div 
          className="absolute inset-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 1 }}
        >
          <div className="absolute inset-0 bg-[radial-gradient(circle,_#fff_1px,_transparent_1px)] [background-size:20px_20px]" />
        </motion.div>
        
        <div className="container mx-auto px-4 text-center text-white relative z-10">
          <motion.h1 
            className="text-5xl md:text-6xl font-bold mb-4"
            {...fadeInUp}
          >
            About Us
          </motion.h1>
          <motion.div 
            className="flex items-center justify-center gap-2 text-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <span className="hover:text-[var(--lightblue-color)] cursor-pointer transition-colors">
             BluBucks
            </span>
            <ChevronRight className="w-4 h-4" />
            <span className="text-[var(--lightblue-color)]">About Us</span>
          </motion.div>
        </div>
      </motion.div>

      {/* Main Content Section */}
      <div className="container mx-auto px-4 py-24">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Left Side - Images */}
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            <motion.div 
              className="relative aspect-[4/3] rounded-2xl overflow-hidden shadow-2xl"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <img
                src={About1}
                alt="Business consultation"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-br from-cyan-600/30 to-purple-600/30 mix-blend-overlay"></div>
              <motion.div 
                className="absolute top-8 left-8 bg-[var(--loanlift-primary-bg)] text-white p-6 rounded-2xl shadow-lg"
                whileHover={{ scale: 1.05, rotate: 5 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="flex items-center justify-center mb-2">
                  <Play className="w-10 h-10" />
                </div>
                <div className="text-4xl font-bold text-[var(--lightblue-color)]">25+</div>
                <div className="text-sm font-medium">Years Of Experience</div>
              </motion.div>
            </motion.div>
            <motion.div 
              className="absolute -bottom-12 -right-12 w-56 h-56 rounded-2xl overflow-hidden shadow-2xl hidden lg:block"
              initial={{ opacity: 0, scale: 0.8, rotate: -10 }}
              whileInView={{ opacity: 1, scale: 1, rotate: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              <img
                src={About2}
                alt="Financial consultation"
                className="w-full h-full object-cover"
              />
            </motion.div>
          </motion.div>

          {/* Right Side - Content */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <motion.span 
              className="text-[var(--lightblue-color)] font-semibold text-lg inline-block"
              {...fadeInUp}
            >
              — WELCOME TO BLUBUCKS
            </motion.span>
            <motion.h2 
              className="text-4xl lg:text-5xl font-bold text-gray-900 leading-tight"
              {...fadeInUp}
            >
              Our Loans Will Turn Your Dreams Into Reality
            </motion.h2>
            <motion.p 
              className="text-gray-600 text-lg"
              {...fadeInUp}
            >
              Blubucks Asset Pvt Ltd, we believe that behind every dream lies the spark of possibility, waiting to be nurtured. Life, in its beauty and unpredictability, often presents moments where a helping hand is all we need to bridge the gap between where we are and where we want to be. We are that hand. We are the step that turns your aspirations into reality.
            </motion.p>
            <motion.div 
              className="grid md:grid-cols-2 gap-6"
              variants={staggerChildren}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
            >
              {[
                { icon: Clock, text: "Quick Loan Process" },
                { icon: Building2, text: "Business Loan" },
                { icon: Percent, text: "Very Low Rates" },
                { icon: GraduationCap, text: "Home Loan" },
                { icon: ShieldCheck, text: "Secure Transactions" },
                { icon: Smile, text: "Customer-Friendly Service" },
                { icon: TrendingUp, text: "Flexible Repayment Options" },
                { icon: Globe, text: "Pan-India Coverage" },
              ].map((item, index) => (
                <motion.div 
                  key={index}
                  className="flex items-center gap-4"
                  variants={fadeInUp}
                >
                  <div className="bg-[var(--loanlift-primary-bg)] p-3 rounded-full">
                    <item.icon className="w-6 h-6 text-[var(--lightblue-color)]" />
                  </div>
                  <span className="text-gray-700 font-medium">{item.text}</span>
                </motion.div>
              ))}
            </motion.div>
            <motion.a
            href='/contact-us'
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center gap-2 bg-black text-white px-8 py-4 rounded-full font-semibold text-lg shadow-lg hover:shadow-xl transition-all duration-300"
            >
              KNOW ABOUT US
              <ArrowRight className="w-5 h-5" />
            </motion.a>
          </motion.div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="bg-gray-100 py-24">
  <div className="container mx-auto px-4">
    <div className="grid lg:grid-cols-2 gap-16 items-center">
      {/* Left Side - Content */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="space-y-8"
      >
        <motion.span 
          className="text-cyan-600 font-semibold text-lg inline-block"
          {...fadeInUp}
        >
          — OUR BENEFITS
        </motion.span>
        <motion.h2 
          className="text-4xl lg:text-5xl font-bold text-gray-900 leading-tight"
          {...fadeInUp}
        >
          Why Choose Us
        </motion.h2>
        <motion.p 
          className="text-gray-600 text-lg"
          {...fadeInUp}
        >
        At Blubucks, we redefine financial empowerment with personalized, transparent, and flexible loan solutions. With a commitment to trust, speed, and innovation, we ensure that every interaction transforms into an opportunity for growth. Choose Blubucks for:
        </motion.p>
        <motion.div 
          className="flex gap-8 flex-wrap"
          variants={staggerChildren}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          {[
            { icon: Percent, text: "Transparent Processes" },
            { icon: Clock, text: "Tailored Solutions" },
            { icon: Clock, text: "Swift Approvals" },
            { icon: Clock, text: "Expert Support" },
          ].map((item, index) => (
            <motion.div
              key={index}
              variants={fadeInUp}
              className="flex flex-1 items-center gap-4"
            >
              <div className="min-w-16 w-16 h-16 rounded-full bg-cyan-100 flex items-center justify-center">
                <item.icon className="w-8 h-8 text-cyan-600" />
              </div>
              <span className="font-semibold text-lg text-gray-800">{item.text}</span>
            </motion.div>
          ))}
        </motion.div>
        <motion.div 
          className="space-y-6 pt-8"
          variants={staggerChildren}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
        >
          {[
            { label: "Loan Process", value: 85 },
            { label: "Business Consultancy", value: 95 },
            { label: "Payments Benefits", value: 90 }
          ].map((item, index) => (
            <motion.div key={index} variants={fadeInUp}>
              <div className="flex justify-between mb-2">
                <span className="font-semibold text-gray-700">{item.label}</span>
                <span className="text-cyan-600 font-bold">{item.value}%</span>
              </div>
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: 0 }}
                  whileInView={{ width: `${item.value}%` }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: index * 0.2 }}
                  className="h-full bg-cyan-600 rounded-full"
                />
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      {/* Right Side - Images */}
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
        <div className="grid grid-cols-12 gap-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="col-span-7 row-span-2"
          >
            <img
              src={why3}
              alt="Business image 1"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="col-span-5 row-span-1"
          >
            <img
              src={why2}
              alt="Business image 2"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="col-span-5 row-span-1"
          >
            <img
              src={why1}
              alt="Business image 3"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
        </div>
        <motion.div 
          className="absolute -bottom-12 -right-12 w-64 h-64 bg-[radial-gradient(circle,_#E5F7FF_1px,_transparent_1px)] [background-size:12px_12px] rounded-full"
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 0.3, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.8 }}
        />
      </motion.div>
    </div>
  </div>
</div>
<div className="bg-white py-24">
  <div className="container mx-auto px-4">
    <div className="grid lg:grid-cols-2 gap-16 items-center">
      {/* Left Side - Content */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="space-y-8"
      >
           <motion.span 
          className="text-cyan-600 font-semibold text-lg inline-block"
          {...fadeInUp}
        >
          — OUR BENEFITS
        </motion.span>
        <motion.h2 
          className="text-4xl lg:text-5xl font-bold text-gray-900 leading-tight"
          {...fadeInUp}
        >
          Our Journey
        </motion.h2>
        <motion.p 
          className="text-gray-600 text-lg"
          {...fadeInUp}
        >
          Born from a simple belief—that access to financial support should be within everyone’s reach—we have grown, not by numbers alone, but by the stories of those we’ve helped. Every loan we provide carries the weight of your hopes, your struggles, your ambitions. And it is with care, compassion, and understanding that we’ve built our path, walking alongside you through every milestone, every challenge.
        </motion.p>
      </motion.div>

      {/* Right Side - Images */}
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src={journey}
              alt="Journey image 1"
             className="w-full  object-cover rounded-lg shadow-lg"
             style={{maxHeight:'500px'}}
            />
          </motion.div>
         
        {/* </div> */}
      </motion.div>
    </div>
  </div>
</div>

<div className="bg-gray-100 py-24">
  <div className="container mx-auto px-4">
    <div className="grid lg:grid-cols-2 gap-16 items-center">
      {/* Left Side - Images */}
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src={whatweoffer}
              alt="Offer image 1"
              className="w-full object-cover rounded-lg shadow-lg"
            style={{maxHeight:'500px'}}
            />
          </motion.div>
         
      </motion.div>

      {/* Right Side - Content */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="space-y-8"
      >
           <motion.span 
          className="text-cyan-600 font-semibold text-lg inline-block"
          {...fadeInUp}
        >
          — What We Offer
        </motion.span>
        <motion.h2 
          className="text-4xl lg:text-5xl font-bold text-gray-900 leading-tight"
          {...fadeInUp}
        >
          What We Offer
        </motion.h2>
        <motion.p 
          className="text-gray-600 text-lg"
          {...fadeInUp}
        >
       At Blubucks, we don’t just offer loans—we offer possibilities. Whether it’s the key to your first home, the lifeline for a budding business, or a solution to life’s unforeseen turns, we are here, ready to support you. With each financial solution we provide—personal loans, home loans, property loan, business funding—our mission remains the same: to empower you, not just financially, but emotionally.
        </motion.p>
      </motion.div>
    </div>
  </div>
</div>

<div className="bg-white py-24">
  <div className="container mx-auto px-4">
    <div className="grid lg:grid-cols-2 gap-16 items-center">
      {/* Left Side - Content */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="space-y-8"
      >
           <motion.span 
          className="text-cyan-600 font-semibold text-lg inline-block"
          {...fadeInUp}
        >
          —Why We Do What We Do
        </motion.span>
        <motion.h2 
          className="text-4xl lg:text-5xl font-bold text-gray-900 leading-tight"
          {...fadeInUp}
        >
          Why We Do What We Do
        </motion.h2>
        <motion.p 
          className="text-gray-600 text-lg"
          {...fadeInUp}
        >
         We know that life doesn’t wait. The unexpected knocks at your door without warning, and sometimes, dreams come at a cost that feels just out of reach. But we believe in more than just numbers and transactions; we believe in the power of trust. We believe in your story. We believe that when given the chance, you will soar.

We do what we do because we understand the urgency of opportunity and the comfort of peace of mind. We are here to guide you, to simplify the complicated, to provide clarity when things feel uncertain.
        </motion.p>
      </motion.div>

      {/* Right Side - Images */}
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
        <div className="grid grid-cols-12 gap-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="col-span-7 row-span-2"
          >
            <img
              src={Aboutimage3}
              alt="Why we do image 1"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="col-span-5 row-span-1"
          >
            <img
              src={Aboutimage3}
              alt="Why we do image 2"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="col-span-5 row-span-1"
          >
            <img
              src={Aboutimage3}
              alt="Why we do image 3"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
        </div>
      </motion.div>
    </div>
  </div>
</div>


<div className="bg-gray-100 py-24">
  <div className="container mx-auto px-4">
    <div className="grid lg:grid-cols-2 gap-16 items-center">
      {/* Left Side - Images */}
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src={promise}
              alt="Offer image 1"
              className="w-full  object-cover rounded-lg shadow-lg"
              style={{maxHeight:'500px'}}
            />
          </motion.div>
         
      </motion.div>

      {/* Right Side - Content */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="space-y-8"
      >
           <motion.span 
          className="text-cyan-600 font-semibold text-lg inline-block"
          {...fadeInUp}
        >
          — Our Promise
        </motion.span>
        <motion.h2 
          className="text-4xl lg:text-5xl font-bold text-gray-900 leading-tight"
          {...fadeInUp}
        >
          Our Promise
        </motion.h2>
        <motion.p 
          className="text-gray-600 text-lg"
          {...fadeInUp}
        >
          We promise to listen before we lend. We promise to walk alongside you, not just as a provider, but as a partner in your journey. Our commitment is not just to meet your needs but to exceed your expectations—offering transparency, flexibility, and unwavering support.

Together, We Build

At Blubucks, we are more than a loan company. We are the bridge between dreams deferred and dreams realized. We are the connection between the present and the future you deserve. Our story is built on yours, and together, we will build a future filled with promise and possibility.

Let us be the wind beneath your wings, lifting you toward the life you envision.

This version taps into the emotional connection and hope people feel when seeking financial support, presenting your company as more than just a lender but as a compassionate partner.
        </motion.p>
      </motion.div>
    </div>
  </div>
</div>

<div className="bg-white py-24">
  <div className="container mx-auto px-4">
    <div className="grid lg:grid-cols-2 gap-16 items-center">
      {/* Left Side - Content */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="space-y-8"
      >
           <motion.span 
          className="text-cyan-600 font-semibold text-lg inline-block"
          {...fadeInUp}
        >
          —About the Founder
        </motion.span>
        <motion.h2 
          className="text-4xl lg:text-5xl font-bold text-gray-900 leading-tight"
          {...fadeInUp}
        >
          About the Founder
        </motion.h2>
        <motion.p 
          className="text-gray-600 text-lg"
          {...fadeInUp}
        >
        The founder of our company is a visionary leader Mr.Suresh Sharma & Mr Tarun Ahuja with a passion for innovation and excellence. With years of industry experience, they have built a strong reputation for driving success and fostering growth. Their commitment to creating impactful solutions has guided the company's mission to deliver outstanding products and services. Under their leadership, the organization continues to thrive and expand, always striving for new heights of achievement.
        </motion.p>
      </motion.div>

      {/* Right Side - Images */}
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="relative"
      >
        <div className="grid grid-cols-12 gap-4" 
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="col-span-7 row-span-2"
          >
            <img
              src={founder1}
              alt="Why we do image 1"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="col-span-5 row-span-1"
          >
            <img
              src={founder2}
              alt="Why we do image 2"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="col-span-5 row-span-1"
          >
            <img
              src={founder3}
              alt="Why we do image 3"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </motion.div>
        </div>
      </motion.div>
    </div>
  </div>
</div>
<PartnerBanks/>
<CustomerService/>
    </div>
  )
}

export default Aboutus

