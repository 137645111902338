import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { Youtube } from 'lucide-react'



const Testimonials = ({testimonials}) => {
  const controls = useAnimation()
  useEffect(() => {
    controls.start({
      x: '-50%',
      transition: {
        duration: 10,
        ease: 'linear',
        repeat: Infinity,
      },
    });
  }, [controls]);

  const handleHoverStart = () => {
    // Stop the animation on hover start
    controls.stop();
  };

  const handleHoverEnd = () => {
    // Resume the animation without resetting it
    controls.start({
      x: '-50%',
      transition: {
        duration: 10,
        ease: 'linear',
        repeat: Infinity,
      },
    });
  };

  return (
    <section className="py-16 overflow-hidden bg-">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-cyan-800 mb-2">Customer stories</h2>
          <div className="w-24 h-1 bg-orange-500 mx-auto"></div>
        </div>

        <div className="relative">
          <div className="flex ">
            <motion.div
              className="flex gap-6 min-w-full"
              animate={controls}
              onHoverStart={handleHoverStart}
              onHoverEnd={handleHoverEnd}
            >
              {/* First set of testimonials */}
              {testimonials?.map((testimonial, index) => (
                <motion.div
                  key={`${testimonial.name}-1-${index}`}
                  className="w-[350px] flex-shrink-0 bg-white rounded-lg border p-6 hover:shadow-xl transition-shadow duration-300"
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-start gap-4 mb-4">
                    <img
                      src={testimonial.avatar}
                      alt={testimonial.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg font-semibold text-cyan-800">{testimonial.name}</h3>
                        <Youtube className="w-5 h-5 text-red-600" />
                      </div>
                      <p className="text-sm text-cyan-600">{testimonial.type}</p>
                    </div>
                  </div>
                  <p className="text-gray-600 text-sm mb-4 line-clamp-3">{testimonial.content}</p>
                  <p className="text-sm text-gray-400">{testimonial.date}</p>
                </motion.div>
              ))}

              {/* Duplicate set for infinite scroll */}
              {testimonials?.map((testimonial, index) => (
                <motion.div
                  key={`${testimonial.name}-2-${index}`}
                  className="w-[350px] flex-shrink-0 bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-start gap-4 mb-4">
                    <img
                      src={testimonial.avatar}
                      alt={testimonial.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg font-semibold text-cyan-800">{testimonial.name}</h3>
                        <Youtube className="w-5 h-5 text-red-600" />
                      </div>
                      <p className="text-sm text-cyan-600">{testimonial.type}</p>
                    </div>
                  </div>
                  <p className="text-gray-600 text-sm mb-4 line-clamp-3">{testimonial.content}</p>
                  <p className="text-sm text-gray-400">{testimonial.date}</p>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials

