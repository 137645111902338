import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, ChevronUp } from "lucide-react";

const DetailSection = ({data}) => {
  const [openSections, setOpenSections] = useState({}); // State to track open/close for each section
  const [showMore, setShowMore] = useState({}); // State for "Show More" functionality

  const toggleShowMore = (index) => {
    setShowMore((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the specific section
    }));
  };

  const toggleSection = (index) => {
    setOpenSections((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the specific section
    }));
  };
  useEffect(() => {
    const length = data?.detail?.length || 0;
    const initialOpenState = Array.from({ length }, (_, index) => ({
      [index]: true,
    })).reduce((acc, curr) => ({ ...acc, ...curr }), {});
    setOpenSections(initialOpenState);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <div className="space-y-6">
      {data?.detail?.map((res, index) => (
        <motion.div
          key={index}
          className="w-full border border-['#0F63AC'] rounded-md overflow-hidden"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <button
            className="flex w-full items-center justify-between py-6 px-8 text-left bg-[var(--loanlift-primary-bg)] text-white transition-all duration-300 hover:from-cyan-600 hover:to-blue-600"
            onClick={() => toggleSection(index)} // Toggle only the clicked section
          >
            <h2 className="text-2xl text-[var(--textwhite-color)] font-bold">
              {res?.title}
            </h2>
            <motion.div
              animate={{ rotate: openSections[index] ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <ChevronDown className="h-6 w-6" />
            </motion.div>
          </button>
          <AnimatePresence initial={false}>
            {openSections[index] && ( // Render only if the section is open
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="overflow-hidden"
              >
                <div className="p-8">
                  {res?.benefits && Array.isArray(res?.benefits) ? (
                       <motion.ol className="list-decimal space-y-4 pl-5 text-gray-600">
                    {res.benefits.map((step, stepIndex) => (
                      <motion.li key={stepIndex} className="text-base">
                        {step?.name}: {step?.description}
                      </motion.li>
                    ))}
                    </motion.ol>
                  ) : res?.sections ? (
                    Array.isArray(res.sections) ? (
                      res.sections.map((item, sectionIndex) => (
                        <div key={sectionIndex} className="mb-4">
                          <h4 className="font-semibold text-gray-800">
                            {item.heading}
                          </h4>
                          <ul className="list-disc pl-6 text-gray-600">
                            {item.points?.map((point, pointIndex) => (
                              <li key={pointIndex}>{point}</li>
                            ))}
                          </ul>
                        </div>
                      ))
                    ) : (
                      res.sections
                    )
                  ) : (
                    <p className="text-gray-600">{res?.description}</p>
                  )}
                  <motion.div className="mt-8 flex justify-center">
                    <button
                      onClick={()=>toggleShowMore(index)}
                      className="group relative inline-flex items-center overflow-hidden rounded bg-[var(--lightblue-color)] px-8 py-2 text-white focus:outline-none focus:ring active:bg-cyan-500"
                    >
                      <span className="absolute right-0 translate-x-full transition-transform group-hover:-translate-x-4">
                        {showMore[index] ? (
                          <ChevronUp className="h-5 w-5" />
                        ) : (
                          <ChevronDown className="h-5 w-5" />
                        )}
                      </span>

                      <span className="text-sm font-medium transition-all group-hover:mr-4">
                        {showMore[index] ? "Show Less" : "Show More"}
                      </span>
                    </button>
                  </motion.div>
                  {showMore[index] && (
                    <motion.p className="mt-6 text-gray-700 text-center italic">
                      {res?.showmsg}
                    </motion.p>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      ))}
    </div>
  );
};

export default DetailSection;
