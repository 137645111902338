import React from 'react';

const cities = [
  'Ahmedabad', 'Hyderabad', 'Lucknow', 'Vapi', 'Udvada', 'Mumbai', 'Bangalore', 
  'Kolkata', 'Pune', 'Valsad', 'Diu', 'Kevadiya', 'Jodhpur', 'Nashik', 'Delhi', 
  'Silvassa', 'Umbergaon', 'Gujarat', 'Varanasi', 'Jaipur', 'Daman', 'Vadodara', 
  'Nagpur', 'Bhopal', 'Mangalore', 'Hazira', 'Saputara', 'Surat', 'Indore', 'Raipur', 
  'Bharuch', 'Vikramgad'
];

const CityShowCase = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-cyan-800 mb-2">We Are Available In These Cities</h2>
          <div className="w-24 h-1 bg-orange-500 mx-auto"></div>
        </div>
        
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6">
          {cities.map((city, index) => (
            <div
              key={index}
              className="flex items-center justify-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <p className="text-lg font-semibold text-cyan-800">{city}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CityShowCase;
