import Crousalimg1 from "../Conatiner/ServicePages/Images/homeloanimg1.avif";
import Crousalimg2 from "../Conatiner/ServicePages/Images/homloanimg2.avif";
import Crousalimg3 from "../Conatiner/ServicePages/Images/homeloanimg3.webp";
import Crousalimg4 from "../Conatiner/ServicePages/Images/home-loans4.webp";
import {
  Check,
  IndianRupee,
  Shield,
  Clock,
  Sparkles,
  HelpCircle,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import girl from '../../creaditcardComponents/cardTypeImages/girl1.png';
import boy from '../../creaditcardComponents/cardTypeImages/boy1.png';

import Document from "../../Loans/personalLoan/Document";
import { title } from "framer-motion/client";

import hom1 from "../Conatiner/ServicePages/Images/home loan/1.png";
import hom2 from "../Conatiner/ServicePages/Images/home loan/2.png";
import hom3 from "../Conatiner/ServicePages/Images/home loan/3.png";
import hom4 from "../Conatiner/ServicePages/Images/home loan/4.png";
import hom5 from "../Conatiner/ServicePages/Images/home loan/5.png";

import persnl1 from "../Conatiner/ServicePages/Images/personal loan/1.png";
import persnl2 from "../Conatiner/ServicePages/Images/personal loan/2.png";
import persnl3 from "../Conatiner/ServicePages/Images/personal loan/3.png";
import persnl4 from "../Conatiner/ServicePages/Images/personal loan/4.png";
import persnl5 from "../Conatiner/ServicePages/Images/personal loan/5.png";
import persnl6 from "../Conatiner/ServicePages/Images/personal loan/6.png";
import persnl7 from "../Conatiner/ServicePages/Images/personal loan/7.png";

import propty1 from "../Conatiner/ServicePages/Images/loan against property/1.png";
import propty2 from "../Conatiner/ServicePages/Images/loan against property/2.png";
import propty3 from "../Conatiner/ServicePages/Images/loan against property/3.png";
import propty4 from "../Conatiner/ServicePages/Images/loan against property/4.png";
import propty5 from "../Conatiner/ServicePages/Images/loan against property/5.png";


import work1 from "../Conatiner/ServicePages/Images/Working CapitalOverDraft Limit Loan/1.png";
import work2 from "../Conatiner/ServicePages/Images/Working CapitalOverDraft Limit Loan/2.png";
import work3 from "../Conatiner/ServicePages/Images/Working CapitalOverDraft Limit Loan/3.png";
import work4 from "../Conatiner/ServicePages/Images/Working CapitalOverDraft Limit Loan/4.png";
import work5 from "../Conatiner/ServicePages/Images/Working CapitalOverDraft Limit Loan/5.png";
import work6 from "../Conatiner/ServicePages/Images/Working CapitalOverDraft Limit Loan/6.png";
import work7 from "../Conatiner/ServicePages/Images/Working CapitalOverDraft Limit Loan/7.png";

import busin1 from "../Conatiner/ServicePages/Images/business loan/1.png";
import busin2 from "../Conatiner/ServicePages/Images/business loan/2.png";
import busin3 from "../Conatiner/ServicePages/Images/business loan/3.png";
import busin4 from "../Conatiner/ServicePages/Images/business loan/4.png";
import busin5 from "../Conatiner/ServicePages/Images/business loan/5.png";


export const HomeLoanData = {
  title: ["Home", "Loan"],
  features: [
    {
      bg: hom1,
      title: "Home loan starting at",
      value: "8.50%* p.a.",
    },
    {
      bg: hom2,
      title: "Minimal Documentation",
      value: "Flexible Plans",
    },
    {
      bg: hom3,
      title: "Loans up to",
      value: "₹15 Crore*",
    },
    {
      bg: hom4,
      title: "30 Years of Tenure",
      value: "",
    },
    {
      bg: hom5,
      title: "Approval in just",
      value: "48 hours*",
    },
  ],

  calculatorfeature: [
    { icon: Check, text: "Lightning-Fast Approval in 48 Hours*" },
    {
      icon: IndianRupee,
      text: "Boost Your Finances with ₹1 Crore*",
    },
    {
      icon: Shield,
      text: "Unrestricted Loan Usage - Your Money, Your Way",
    },
    { icon: Clock, text: "Close Your Loan Anytime with Ease" },
    { icon: Sparkles, text: "Smart EMIs That Fit Your Budget" },
  ],
  detail: [
    {
        title: "Features and Benefits of our Home Loan",
        showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
        benefits: [
          {
            name: "Home Construction Loan",
            description:
              "If you have a plot of land and lack financial support to construct a home on that piece of property, you may choose a house construction loan.",
          },
          {
            name: "Home Extension Loan",
            description:
              "Should you already own a home, and feel the need to extend the building, add an annexe, or build another floor, etc., Home Extension Loan is your go-to option that finances such needs.",
          },
          {
            name: "Home Improvement Loan",
            description:
              "All of us wish to improve and upgrade our homes for a better life. From your old-age furniture, fixtures to faulty lighting systems, Home Improvement Loan funds all your plans.",
          },
          {
            name: "NRI Home Loan",
            description:
              "If you are a Non-Resident Indian and wish to own residential property in India, NRI Home Loan is your answer. Though the formalities for application may be a little different.",
          },
        ],
    },
    {
        "title": "Home Loan Eligibility and Documents",
        "description": "Read on to know the criteria required to apply for our Home Loan.",
        showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
       
        "sections": [
          {
            "title": "Home Loan Eligibility",
            "description": "The basic home loan eligibility criteria are as follows:"
          },
          {
            "heading": "Age",
            "points": [
              "You must be above 21 years of age at the time of the commencing of your loan, and up to 65 years or less at loan maturity",
              "Salaried Person - 21 years to 58 years",
              "Self-Employed Person - 25 years to 65 years"
            ]
          },
          {
            "heading": "Income",
            "points": [
              "Salaried Person - Minimum Rs. 10,000 per month",
              "Self-Employed Person - Minimum Rs. 2,00,000 per year"
            ]
          },
          {
            "heading": "Employment",
            "points": [
              "Salaried Person - Minimum 2 to 3 years of Experience in MNC, or a Private or Public Limited Company",
              "Self-Employed Person - Minimum 3 years of Experience in the current field"
            ]
          },
          {
            "heading": "Credit Score",
            "points": [
              "A credit score of 750 is considered a good score for approval."
            ]
          }
        ]
      },
      {
        "title":"Documents Required to Apply for home loan salaried",
        showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",

        "sections": <Document loanType="home_loan_salaried" />

      },
      {
        "title":"Documents Required to Apply for Self Employed",
        showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",

        "sections": <Document loanType="home_loan_self_employed" />

      }
  ],
  "testimonials":[
    {
      name: 'Sana sheikh',
      type: 'Home Loan',
      date: '07-jan-2020',
      content: 'my name is karim sheikh. i have received a home loan from punjab and sind bank, ahmednagar branch. i am very much happy and satisfied with the services provided by punjab and sind bank. special thanks to the',
      avatar: girl
    },
    {
      name: 'rajesh mishra',
      type: 'Personal Loan',
      date: '25-dec-2019',
      content: 'hello. i am rajesh mishra. i have taken a personal loan from the state bank of india with all the processes. i got the loan within 59 minutes. i am very much happy with the bank and process. thank you.',
      avatar: boy
    },
    {
      name: 'viplav jangir',
      type: 'Personal Loan',
      date: '25-dec-2019',
      content: 'my name is viplav jangir. i applied for a personal loan from the oriental bank of commerce, kolkata, west bengal. i did not face any hurdles. bank employees cooperated with me well. i received the loan',
      avatar: girl
    },
    {
      name: 'prasanna bhatiavande',
      type: 'Auto Loan',
      date: '28-jan-2020',
      content: 'hello. my name is prasanna bhatiavande. i am a resident of pandharpur, solapur, maharashtra. i am a dentist. i have recently purchased a car. for this, i have availed of an auto loan from the bank of india, pandharpur',
      avatar: boy
    },
    {
      name: 'Juned K',
      type: 'Auto Loan',
      date: '28-jan-2020',
      content: 'hi. my name is ashraf dalaal. my firm name is dalal wire. i have applied for an auto loan from the oriental bank of commerce. the branch staffs response was very good. thanks to the oriental bank of commerce.',
      avatar: girl
    },
    {
      name: 'vijay suresh mehta',
      type: 'MSME Loan',
      date: '01-mar-2019',
      content: 'my name is vijay suresh mehta, director of mass square india private limited. ssi unit based out at bandgore, specializing in process control &amp; industrial automation. this platform is a very big boon',
      avatar: boy
    }
  ]
};
export const PersonalData = {
    title: ["Personal", "Loan"],
    features: [
      {
        bg: persnl1,
        title: "Personal loan starting at",
        value: "8.50%* p.a.",
      },
      {
        bg: persnl2,

        value:"offers for New Customers"
      },
      {
        bg: persnl3,
        title: "Minimal Documentation",
        value: "Flexible Plans",
      },
      {
        bg: persnl4,
        title: "Loan of up to",
        value: "₹40 Lakh*",
      },
      {
        bg: persnl5,
        value:"3 Unique Variants"
      },
      {
        bg:    persnl6,  
        value: 'No Guarantor / Collateral',
      },
      {
        bg: persnl7,
        value: "Tenure - Max 4 Years",
      },
    ],
  
    calculatorfeature: [
      { icon: Check, text: "Lightning-Fast Approval in 48 Hours*" },
      {
        icon: IndianRupee,
        text: "Boost Your Finances with ₹1 Crore*",
      },
      {
        icon: Shield,
        text: "Unrestricted Loan Usage - Your Money, Your Way",
      },
      { icon: Clock, text: "Close Your Loan Anytime with Ease" },
      { icon: Sparkles, text: "Smart EMIs That Fit Your Budget" },
    ],
    detail: [
      {
          title: "Features and Benefits of our Personal Loan",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
          benefits: [
            {
                "name": "Debt consolidation",
                "description": "Combining multiple debts into a single loan with a lower interest rate to save money on interest payments and make it easier to manage debts."
              },
              {
                "name": "Home renovation",
                "description": "Using a personal loan to fund home renovation projects can increase the value of your home and improve your living conditions."
              },
              {
                "name": "Medical expenses",
                "description": "If you have unexpected medical expenses, such as emergency surgery or hospitalization, a personal loan can help cover the costs."
              },
              {
                "name": "Emergency expenses",
                "description": "A personal loan can help cover unexpected expenses like medical emergencies or car repairs to avoid financial hardship."
              }
          ],
      },
      {
          "title": "Personal Loan Eligibility and Documents",
          "description": " To qualify for a personal loan, you have to meet certain criteria. Below are the important factors that lenders take into consideration to decide your eligibility for a personal loan.",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
         
          "sections": [
            {
              "title": "Home Loan Eligibility",
              "description": "The basic home loan eligibility criteria are as follows:"
            },
            {
              "heading": "",
              "points": [
                "Age should fall under the range of 21 years to 60 years",
    "Net monthly income should be 15,000/- for salaried and self-employed, yearly transactions should be a minimum of 20 lakhs",
    "Credit score must be above 650",
    "Debt-to-income ratio",
    "Employment stability",
    "Maintained a good credit score",
    "Clear repayment history",
    "Must be a Resident Citizen of India"
              ]
            },
           
          ]
        },
        {
          "title":"Documents Required to Apply for Personal Loan",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
  
          "sections": <Document loanType="personal_loan_salaried" />
  
        },
        
    ],
    "testimonials": [
        {
          "name": "Arun Patel",
          "type": "Personal Loan",
          "date": "07-jan-2020",
          "content": "I am Arun Patel, and I applied for a personal loan from ICICI Bank, Ahmedabad branch. The process was fast and transparent, and I received the loan amount within a few days. The team at the bank was very supportive, making the entire experience hassle-free. I highly recommend ICICI for personal loans.",
          "avatar": girl
        },
        {
          "name": "Neha Gupta",
          "type": "Personal Loan",
          "date": "25-dec-2019",
          "content": "My name is Neha Gupta. I needed a personal loan for some urgent medical expenses, and I approached HDFC Bank. The loan was processed very quickly, and the entire procedure was straightforward. The staff was helpful in guiding me through every step, and I am grateful for their support.",
          "avatar": boy
        },
        {
          "name": "Rajiv Sharma",
          "type": "Personal Loan",
          "date": "25-dec-2019",
          "content": "I am Rajiv Sharma. I took a personal loan from Axis Bank to help with my wedding expenses. The loan approval was fast, and the terms were very reasonable. I appreciate the customer service provided by the bank, and I’m very happy with how smoothly everything went.",
          "avatar": girl
        },
        {
          "name": "Sita Rani",
          "type": "Personal Loan",
          "date": "28-jan-2020",
          "content": "I am Sita Rani from Patiala. I needed a personal loan to renovate my home and approached Punjab National Bank. I received the loan amount promptly, and the entire process was very easy to follow. The staff at PNB was very helpful, and I am extremely satisfied with their service.",
          "avatar": boy
        },
        {
          "name": "Ravi Kumar",
          "type": "Personal Loan",
          "date": "28-jan-2020",
          "content": "Hello, I am Ravi Kumar. I applied for a personal loan with Kotak Mahindra Bank to support my children’s education. The loan was processed quickly, and the team at Kotak Mahindra was very professional in handling my queries. I am grateful for their support throughout the process.",
          "avatar": girl
        },
        {
          "name": "Simran Kaur",
          "type": "Personal Loan",
          "date": "01-mar-2019",
          "content": "I’m Simran Kaur, and I took a personal loan from the State Bank of India for a family emergency. The process was seamless, and I received the loan in just a few days. I am thankful to the team at SBI for making it a smooth experience and for being so responsive to my needs.",
          "avatar": boy
        }
      ]
  };
  
  export const PropertyData = {
    title: ["Loan Against Property", "Loan"],
    features: [
      {
        bg: propty1,
        title: "Property loan starting at",
        value: "8.50%* p.a.",
      },
      {
        bg: propty2,
        title: "Minimal Documentation",
        value: "Flexible Plans",
      },
      {
        bg: propty3,
        title: "Loans value from ",
        value: "₹10 Lakh to ₹5 Crore*",
      },
      {
        bg: propty4,
        title: "15 Years of Tenure",
        value: "",
      },
      {
        bg: propty5,
        title: "Loan for your business as well as personal needs",
      },
    ],
  
    calculatorfeature: [
      { icon: Check, text: "Lightning-Fast Approval in 48 Hours*" },
      {
        icon: IndianRupee,
        text: "Boost Your Finances with ₹1 Crore*",
      },
      {
        icon: Shield,
        text: "Unrestricted Loan Usage - Your Money, Your Way",
      },
      { icon: Clock, text: "Close Your Loan Anytime with Ease" },
      { icon: Sparkles, text: "Smart EMIs That Fit Your Budget" },
    ],
    detail: [
      {
          title: " Features and Benefits of our Loan Against Property",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
          benefits: [
            {
              "name": "Secured Loan",
              "description": "The loan is secured against the value of your property. The property acts as collateral, reducing the risk for the lender. This generally leads to lower interest rates compared to unsecured loans."
            },
            {
              "name": "Loan Amount",
              "description": "The loan amount is determined based on the value of the property you pledge. Generally, you can get a higher loan amount compared to personal loans or other unsecured loans."
            },
            {
              "name": "Flexible Tenure",
              "description": "The tenure (repayment period) for a Loan Against Property is usually longer compared to other types of loans, often ranging from 5 to 20 years. This allows for lower monthly installments."
            },
            {
              "name": "Multipurpose",
              "description": "The loan amount can be used for a wide range of purposes, giving you the flexibility to address various financial needs without any restrictions."
            },
            {
              "name": "Improves Credit Scores",
              "description": "Successfully repaying a Loan Against Property can positively impact your credit score, as it demonstrates responsible borrowing behavior."
            }
          ]
      },
      {
          "title": " Eligibility Criteria for Loan against Property",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
         
          "sections": [
            {
              "title": "Home Loan Eligibility",
              "description": "The basic home loan eligibility criteria are as follows:"
            },
            {
              "heading": "Nationality",
              "points": [
                "You need to be a Citizen of India with documents to prove your claim.",
              ]
            },
            {
              "heading": "Occupation and Income",
              "points": [
                "Your lender will require you to furnish details regarding your occupation and income to prove your professional and financial stability to determine your creditworthiness.",

              ]
            },
            {
              "heading": "Credit History",
              "points": [
                "Your three-digit Credit Score, indicative of your track record in respect of repayment of loans, and other forms of credit will be a deciding factor to prove your eligibility for a LAP."
              ]
            },
            {
              "heading": "Banking Relationship",
              "points": [
                "Should you have a healthy relationship with your lender, you will not be disapproved for a LAP. Additionally, your lender will offer you better terms and conditions in respect of loan value, interest rates, period of the loan, hidden charges, and processing fees."
              ]
              
            },
            {
                "heading": "Market Value of Property",
                "points": [
                  "Your lender retains the right to decide the loan amount and terms and conditions of your mortgage loan based on the market value of your collateral property. Besides, the market value of the mortgaged property must be higher than the loan amount calculated on the current value of your property."
                ]
                
              }
              ,
            {
                "heading": "Title of Property",
                "points": [
                  "Your lender will require you to be the current existent owner of the property, and in case of a co-application, you will require to prove multiple ownership clear title. Besides, the property must not be mortgaged with any other financial institution."
                ]
                
              }
          ]
        },
        {
          "title":"Documents Required to Apply for loan Against Property salaried",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
  
          "sections": <Document loanType="loan_against_property_salaried" />
  
        },
        {
          "title":"Documents Required to Apply for loan Against Property Self Employed",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
          "sections": <Document loanType="loan_against_property_self_employed" />
  
        }
    ],
    "testimonials":[
        {
          "name": "Ravi Sharma",
          "type": "Loan Against Property",
          "date": "15-Jan-2021",
          "content": "My name is Ravi Sharma. I took a Loan Against Property from HDFC Bank. The process was quick and smooth. The staff was very helpful, and I was able to get a loan amount that helped me in fulfilling my business needs. I am extremely happy with the service.",
          "avatar": girl
        },
        {
          "name": "Anita Verma",
          "type": "Loan Against Property",
          "date": "10-Dec-2020",
          "content": "I am Anita Verma, and I recently availed a Loan Against Property from ICICI Bank. The loan amount was based on the value of my property, and the interest rate was very competitive. I am pleased with the way the bank handled my application and would recommend it to others.",
          "avatar": boy
        },
        {
          "name": "Ajay Kumar",
          "type": "Loan Against Property",
          "date": "20-Nov-2020",
          "content": "My name is Ajay Kumar. I applied for a Loan Against Property from Axis Bank. I was able to get a substantial loan amount, which helped me renovate my house. The process was hassle-free, and the bank’s staff provided excellent support throughout.",
          "avatar": girl
        },
        {
          "name": "Priya Mehta",
          "type": "Loan Against Property",
          "date": "28-Feb-2021",
          "content": "Hello, I’m Priya Mehta, a resident of Pune. I applied for a Loan Against Property from the State Bank of India to fund my child's education. The bank offered flexible repayment terms, and I could easily meet my financial goals with the loan amount received.",
          "avatar": boy
        },
        {
          "name": "Suresh Patel",
          "type": "Loan Against Property",
          "date": "12-Jan-2021",
          "content": "I am Suresh Patel. I applied for a Loan Against Property from Bank of Baroda for my business expansion. The process was quick, and the interest rates were very competitive. The customer service was excellent, and I was able to access the funds without any issues.",
          "avatar": girl
        },
        {
          "name": "Sanjay Singh",
          "type": "Loan Against Property",
          "date": "05-Mar-2019",
          "content": "I am Sanjay Singh, the owner of a small business. I applied for a Loan Against Property from Punjab National Bank, and I must say the experience was seamless. The loan helped me expand my business, and the bank provided good guidance throughout the process.",
          "avatar": boy
        }
      ]
  };

  export const WorkingCapitalData = {
    title: ["Working Capital/OverDraft Limit", "Loan"],
    features: [
      {
        bg: work1,
        title: "Personal loan starting at",
        value: "8.50%* p.a.",
      },
      {
        bg: work2,

title:" title: 'Offers for New Customers',"
      },
      {
        bg: work3,
        title: "Minimal Documentation",
        value: "Flexible Plans",
      },
      {
        bg: work4,
        title: "Loan of up to",
        value: "₹40 Lakh*",
      },
      {
        bg: work5,
        title:"3 Unique Variants"
      },
      {
        bg:    work6,  
        title: 'No Guarantor / Collateral',
      },
      {
        bg: work7,
        title: "Tenure - Max 4 Years",
        value: "",
      },
    ],
  
    calculatorfeature: [
      { icon: Check, text: "Lightning-Fast Approval in 48 Hours*" },
      {
        icon: IndianRupee,
        text: "Boost Your Finances with ₹1 Crore*",
      },
      {
        icon: Shield,
        text: "Unrestricted Loan Usage - Your Money, Your Way",
      },
      { icon: Clock, text: "Close Your Loan Anytime with Ease" },
      { icon: Sparkles, text: "Smart EMIs That Fit Your Budget" },
    ],
    detail: [
      {
          title: "Features of Working Capital/Overdraft Limit",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
          benefits: [
            {
              "name": "Flexible Borrowing",
              "description": "Access funds as needed up to a predefined limit."
            },
            {
              "name": "Interest on Usage",
              "description": "Interest is charged only on the amount utilized, not the entire limit."
            },
            {
              "name": "Revolving Facility",
              "description": "Can withdraw and repay multiple times within the limit."
            },
            {
              "name": "No Fixed Repayment Schedule",
              "description": "Repay as per your convenience, based on cash flow."
            },
            {
              "name": "Quick Access",
              "description": "Immediate availability of funds when required."
            },
            {
              "name": "Collateral Options",
              "description": "Can be secured or unsecured based on the business profile."
            },
            {
              "name": "Helps Maintain Liquidity",
              "description": "Supports smooth operations during cash flow fluctuations."
            }
          ]
          
          
      },
      {
          "title": "Personal Eligibility Criteria for Working Capital/Overdraft Limit",
        //   "description": " To qualify for a personal loan, you have to meet certain criteria. Below are the important factors that lenders take into consideration to decide your eligibility for a personal loan.",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
         
          benefits: [
            {
              "name": "Business Ownership",
              "description": "Must own or manage a registered business."
            },
            {
              "name": "Business Vintage",
              "description": "The business should have been operational for a minimum period, typically 1-3 years."
            },
            {
              "name": "Stable Income",
              "description": "Proof of regular cash flow or revenue is required."
            },
            {
              "name": "Good Credit Score",
              "description": "A strong personal and business credit score improves eligibility."
            },
            {
              "name": "Collateral (if required)",
              "description": "Assets or guarantees may be needed for secured limits."
            },
            {
              "name": "Business Financials",
              "description": "Submission of balance sheets, profit & loss statements, and tax returns."
            },
            {
              "name": "Compliance with Lender’s Policies",
              "description": "Must meet any additional bank-specific requirements."
            }
          ]
        },
        {
          "title":"Documents Required to Apply for Working Capital/OverDraft Limit Loan",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
  
          "sections": <Document loanType="Working_Capital/Overdraft_Limit" />
  
        },
        
    ],
    "testimonials":  [
        {
          "name": "Amit Desai",
          "type": "Working Capital Loan",
          "date": "07-jan-2020",
          "content": "I am Amit Desai, and I applied for a working capital loan from ICICI Bank to fund my business operations. The process was smooth and quick. ICICI’s team helped me understand the terms, and I received the funds on time, which really helped my business maintain its cash flow. Highly recommend ICICI for business financing.",
          "avatar": girl
        },
        {
          "name": "Meera Joshi",
          "type": "Working Capital Loan",
          "date": "25-dec-2019",
          "content": "My name is Meera Joshi, and I applied for a working capital loan with HDFC Bank to manage my inventory. The loan process was seamless, and I received the amount quickly. The support team was excellent in guiding me through each step, and I’m very happy with the service provided.",
          "avatar": boy
        },
        {
          "name": "Ravi Singh",
          "type": "Overdraft Loan",
          "date": "25-dec-2019",
          "content": "I am Ravi Singh, and I applied for an overdraft loan from Axis Bank to cover my business’s short-term expenses. The approval process was fast, and the overdraft facility provides me the flexibility to withdraw funds as needed. The customer service was great, and I’m happy with how the process went.",
          "avatar": girl
        },
        {
          "name": "Anjali Sharma",
          "type": "Overdraft Loan",
          "date": "28-jan-2020",
          "content": "I am Anjali Sharma from Ludhiana, and I needed an overdraft loan to support my business during a cash flow gap. Punjab National Bank made the process easy and quick. The overdraft facility has helped me manage my business operations with ease. I am grateful for their support and professionalism.",
          "avatar": boy
        },
        {
          "name": "Karan Mehta",
          "type": "Working Capital Loan",
          "date": "28-jan-2020",
          "content": "Hello, I am Karan Mehta, and I applied for a working capital loan with Kotak Mahindra Bank to expand my retail business. The loan was processed without any hassle, and the funds were disbursed quickly. Kotak Mahindra’s team was very supportive in answering my queries, and the process was smooth and transparent.",
          "avatar": girl
        },
        {
          "name": "Harpreet Kaur",
          "type": "Working Capital Loan",
          "date": "01-mar-2019",
          "content": "I’m Harpreet Kaur, and I took a working capital loan from the State Bank of India to fund my manufacturing unit. The loan was processed very quickly, and I received the funds in just a few days. The team at SBI was very responsive and provided all the support I needed throughout the process.",
          "avatar": boy
        }
      ]
  };
  
  export const BusinessData = {
    title: ["Business", "Loan"],
    features: [
      {
        bg:busin1,
        title: "Home loan starting at",
        value: "8.50%* p.a.",
      },
      {
        bg:busin2,
        title: "Minimal Documentation",
        value: "Flexible Plans",
      },
      {
        bg:busin3,
        title: "Loans up to",
        value: "₹15 Crore*",
      },
      {
        bg:busin4,
        title: "30 Years of Tenure",
        value: "",
      },
      {
        bg:busin5,
        title: "Approval in just",
        value: "48 hours*",
      },
    ],
  
    calculatorfeature: [
      { icon: Check, text: "Lightning-Fast Approval in 48 Hours*" },
      {
        icon: IndianRupee,
        text: "Boost Your Finances with ₹1 Crore*",
      },
      {
        icon: Shield,
        text: "Unrestricted Loan Usage - Your Money, Your Way",
      },
      { icon: Clock, text: "Close Your Loan Anytime with Ease" },
      { icon: Sparkles, text: "Smart EMIs That Fit Your Budget" },
    ],
    detail: [
      {
          title: "Features and Benefits of our Business Loans",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
          sections: [
            {
                heading: "Features and Benefits of our Business Loans",
                points:[
                    "Term Loans",
                    "Working Capital Loans",
                    "Business Expansion Loans",
                    "Equipment Financing Loans",
                    "Invoice Financing & Bill Discounting",
                    "Machinery Loans"
                  ] },
          
          ],
      },
      {
          "title": "Home Loan Eligibility and Documents",
          "description": "Read on to know the criteria required to apply for our Home Loan.",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
         
          "sections":  [
            {
              "heading": "Business Type",
              "points": [
                "Most lenders provide loans to various types of businesses, including sole proprietorships, partnerships, limited liability companies (LLCs), corporations, and more."
              ]
            },
            {
              "heading": "Credit Score",
              "points": [
                "A good credit score is often required to qualify for a business loan. Lenders use your credit history to assess your ability to repay the loan."
              ]
            },
            {
              "heading": "Business Age",
              "points": [
                "Some lenders require a minimum operational history for your business, usually ranging from a few months to a year or more."
              ]
            },
            {
              "heading": "Annual Revenue",
              "points": [
                "Lenders may have a minimum annual revenue requirement to ensure your business has a stable income."
              ]
            },
            {
              "heading": "Collateral",
              "points": [
                "Secured loans might require assets as collateral to secure the loan. Collateral can be real estate, equipment, inventory, or other valuable assets."
              ]
            },
            {
              "heading": "Cash Flow",
              "points": [
                "Lenders often evaluate your business's cash flow to determine your ability to repay the loan."
              ]
            },
            {
              "heading": "Age Criteria",
              "points": [
                "Min. 21 years at the time of loan application & Max. 65 years at the time of loan maturity."
              ]
            },
            {
              "heading": "Eligible Entities",
              "points": [
                "Individuals, MSMEs, Sole Proprietorships, Partnership Firms, Public and Private Limited Companies, Limited Liability Partnerships, retailers, traders, manufacturers, and other non-farm income-generating business entities engaged only in the services, trading, and manufacturing sectors."
              ]
            },
            {
              "heading": "Business Vintage",
              "points": [
                "Min. 1 year or above."
              ]
            },
            {
              "heading": "Business Experience",
              "points": [
                "Min. 1 year, business location to remain same."
              ]
            },
            {
              "heading": "Annual Turnover",
              "points": [
                "Shall be defined by the Bank NBFC."
              ]
            },
            {
              "heading": "Credit Score",
              "points": [
                "700 or above (Preferred by most private and public sector banks)."
              ]
            },
            {
              "heading": "Nationality",
              "points": [
                "Indian citizens."
              ]
            },
            {
              "heading": "Additional Criteria",
              "points": [
                "Applicants must own either a residence, office, shop, or Godown."
              ]
            }
          ]
        },
        {
          "title":"Documents Required to Apply for Business loan",
          showmsg:"That's it! Your application is submitted. Our representative will connect with you and guide you through the next steps.",
  
          "sections": <Document loanType="business_loan" />
  
        },
       
    ],
    "testimonials":[
        {
          "name": "Sana Sheikh",
          "type": "Business Loan",
          "date": "07-jan-2020",
          "content": "My name is Sana Sheikh. I have received a business loan from Punjab and Sind Bank, Ahmednagar branch. I am very happy and satisfied with the services provided by Punjab and Sind Bank. Special thanks to the team for their support during the process.",
          "avatar": girl
        },
        {
          "name": "Rajesh Mishra",
          "type": "Business Loan",
          "date": "25-dec-2019",
          "content": "Hello, I am Rajesh Mishra. I took a business loan from the State Bank of India. The process was smooth, and I received the loan amount within 59 minutes. I'm very happy with the bank's services and their promptness.",
          "avatar": boy
        },
        {
          "name": "Viplav Jangir",
          "type": "Business Loan",
          "date": "25-dec-2019",
          "content": "My name is Viplav Jangir. I applied for a business loan from Oriental Bank of Commerce, Kolkata, West Bengal. The process was hassle-free, and the bank employees cooperated well throughout. I received the loan on time, and the overall experience was great.",
          "avatar": girl
        },
        {
          "name": "Prasanna Bhatiavande",
          "type": "Business Loan",
          "date": "28-jan-2020",
          "content": "Hello, my name is Prasanna Bhatiavande, a dentist from Pandharpur, Solapur, Maharashtra. I recently expanded my clinic and availed a business loan from Bank of India, Pandharpur. The process was smooth, and I received the loan quickly.",
          "avatar": boy
        },
        {
          "name": "Juned K",
          "type": "Business Loan",
          "date": "28-jan-2020",
          "content": "Hi, my name is Ashraf Dalaal, and my firm is Dalal Wire. I applied for a business loan from Oriental Bank of Commerce. The staff at the branch was very responsive, and the loan process was very easy. Thanks to Oriental Bank of Commerce for their excellent support.",
          "avatar": girl
        },
        {
          "name": "Vijay Suresh Mehta",
          "type": "Business Loan",
          "date": "01-mar-2019",
          "content": "My name is Vijay Suresh Mehta, director of Mass Square India Private Limited, a small-scale industry based in Bandgore, specializing in process control and industrial automation. The business loan I received has been a significant boost for our operations, and I am grateful for the support provided.",
          "avatar": boy
        }
      ]
  };