

import { motion } from "framer-motion";
import { PhoneCall, MessageSquare, MapPin, MessageCircle, Bot, Phone, ChevronLeft, ChevronRight } from 'lucide-react';
import { useState, useRef, useEffect } from "react";
import leftImage from "./image/contact us.png";
import Address from "./image/location.png";
import whatsApp from "./image/connect me.png";
import calling from './image/customer care.png';
import RegisterFrom from "../EnquiryForm/RegisterFrom";

import imgloanagainst from "./image/loanagainstproperty.png"
import homeLoan from "./image/homeloan.png"
import personal from "./image/personalloan.png"
import business from "./image/business.png"
import working from "./image/working.png"


export default function CustomerService() {
  const [activeTab, setActiveTab] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const menuItems = [
    { icon: <PhoneCall className="w-5 h-5" />, text: "Get A Callback", color: "bg-yellow-400" },
    // { icon: <MessageSquare className="w-5 h-5" />, text: "Connect Online" },
    { icon: <MapPin className="w-5 h-5" />, text: "Locate Us" },
    { icon: <MessageCircle className="w-5 h-5" />, text: "WhatsApp" },
    // { icon: <Bot className="w-5 h-5" />, text: "Chatbot" },
    { icon: <Phone className="w-5 h-5" />, text: "Customer Contact No." },
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const menuItemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        const slideWidth = sliderRef.current.offsetWidth;
        sliderRef.current.scrollLeft = currentSlide * slideWidth;
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentSlide]);

  const handleSlideChange = (direction) => {
    if (direction === 'next' && currentSlide < menuItems.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else if (direction === 'prev' && currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="max-w-4xl mx-auto p-4 md:p-8"
          >

          </motion.div>
        );
      // case 1:
      //   return (
      //     <motion.div
      //       variants={containerVariants}
      //       initial={{ opacity: 0, x: -50 }}
      //       animate={{ opacity: 1, x: 0 }}
      //       className="max-w-4xl mx-auto p-4 md:p-8"
      //     >
      //       <div className="flex flex-col md:flex-row items-center gap-8">
      //         <div className="w-full md:w-1/2">
      //           <img src={MobileApp} alt="Planet App Interface" className="w-full max-w-sm mx-auto" />
      //         </div>
      //         <div className="w-full  md:w-1/2 space-y-6">
      //           <div className="flex items-center justify-center gap-4">
      //             <h2 className="text-2xl font-bold">Download the Our App.</h2>
      //           </div>
      //           <div className=" gap-4 flex items-center justify-center">
      //             <img src={badgeApple} alt="App Store" className="h-12" />
      //             <img src={badgeGoogle} alt="Play Store" className="h-12" />
      //           </div>
      //           <div className="w-full p-6 rounded-lg shadow-md">
      //             <div className="flex flex-col md:flex-row justify-between items-center ">
      //               <h3 className=" text-xl font-semibold">Know More About App</h3>
      //               <button type="submit" class="w-full  text-black font-semibold py-4 rounded-xl  transition-colors text-lg ">
      //                 <a href="#_" class="relative rounded px-5 py-2.5 overflow-hidden group bg-yellow-500 hover:bg-gradient-to-r hover:from-yellow-500 hover:to-cyan-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300 w-full h-full flex items-center justify-center">
      //                   <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
      //                   <span class="relative">Click Here</span>
      //                 </a>
      //               </button>
      //             </div>
      //           </div>
      //           <p className="text-center">
      //             If you prefer Self Help Option (SHO) <a href="#" className="text-blue-500 hover:underline ms-2">Click here</a>
      //           </p>
      //         </div>
      //       </div>
      //     </motion.div>
      //   );
      case 1:
        return (
          <motion.div
            variants={containerVariants}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            className="max-w-5xl mx-auto p-4 md:p-8"
          >
            <div className="flex flex-col md:flex-row items-center gap-12">
              <div className="w-full md:w-1/2">
                <img src={Address} alt="Location Illustration" className="w-full max-w-sm mx-auto" />
              </div>
              <div className="w-full md:w-1/2 space-y-4">
                <h2 className="text-2xl font-bold text-cyan-500">Corporate Office</h2>
                <address className="not-italic text-gray-800 space-y-1">
                  <p>706 , 707 vishwadeep Tower District Centre Janakpuri</p>
                  <p>Delhi 110058</p>
                </address>
                {/* <div className="pt-4">
                  <p className="font-medium">To visit a branch of L&T Finance please</p>
                  <a href="#" className="text-blue-500 hover:underline">Click here</a>
                </div> */}
              </div>
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            variants={containerVariants}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            className="max-w-5xl mx-auto p-4 md:p-8"
          >
            <div className="flex flex-col md:flex-row items-center gap-10">
              <div className="w-full md:w-1/2">
                <img src={whatsApp} alt="WhatsApp Illustration" className="w-full max-w-sm mx-auto" />
              </div>
              <div className="w-full md:w-1/2">
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <h2 className="text-2xl font-bold mb-4">
                    Connect With us on <span className="text-green-500">WhatsApp!</span>
                  </h2>
                  <p className="text-gray-600">
                    Send 'Hi' or 'Hello' to :  <a href="https://wa.me/8505975050" className="text-2xl text-[var(--lightblue-color)] hover:text-green-500">
    <i className="fab fa-whatsapp"></i> +918505975050
  </a>
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        );
      // case 3:
      //   return (
      //     <motion.div
      //       variants={containerVariants}
      //       initial={{ opacity: 0, x: -50 }}
      //       animate={{ opacity: 1, x: 0 }}
      //       className="max-w-4xl mx-auto p-4 md:p-8"
      //     >
      //       <div className="flex flex-col md:flex-row items-center gap-12">
      //         <div className="w-full md:w-1/2">
      //           <img src={Chatbot} alt="Chatbot Illustration" className="w-full max-w-sm mx-auto" />
      //         </div>
      //         <div className="w-full md:w-1/2 text-center">
      //           <h2 className="text-2xl font-bold mb-2">Have more queries?</h2>
      //           <p className="text-gray-600 mb-6">Get them answered instantly.</p>
      //           <button type="submit" class="  text-black font-semibold py-4 rounded-xl  transition-colors text-lg ">
      //             <a href="#_" class="relative rounded px-5 py-2.5 overflow-hidden group bg-yellow-500 hover:bg-gradient-to-r hover:from-yellow-500 hover:to-cyan-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300 w-full h-full flex items-center justify-center">
      //               <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
      //               <span class="relative">Say Hello</span>
      //             </a>
      //           </button>
      //         </div>
      //       </div>
      //     </motion.div>
      //   );
      case 3:
        return (
          <motion.div
            variants={containerVariants}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            className="max-w-6xl mx-auto p-4 md:p-8"
          >
            <div className="flex flex-col md:flex-row items-start gap-5">
              <div className="w-full flex item-center justify-center md:w-1/3">
                <img src={calling} alt="Customer Care Illustration" className="w-full  mx-auto" />
              </div>
              <div className="w-full md:w-2/3 text-left">
                <h2 className="text-2xl font-bold mb-4">Customer Care</h2>
                <p className="text-gray-600 mb-6">You may Call, SMS or e-Mail us for your loan related needs.</p>

                <div className="space-y-6">
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <div className="flex gap-4 mb-2">
                      <img src={imgloanagainst} alt="Personal Loan" className="w-8 h-8" />
                      <img src={homeLoan} alt="Two Wheeler" className="w-8 h-8" />
                      <img src={personal} alt="Farm Equipment" className="w-8 h-8" />
                      <img src={business} alt="Farm Equipment" className="w-8 h-8" />
                      <img src={working} alt="Farm Equipment" className="w-8 h-8" />
                    </div>
                    <p className="font-medium">Personal Loans | Home Loans | Buiness Loan | Loan Against Property | Working Capital/Overdraft limit</p>
                    <p className="text-gray-600">support@blubucks.in | +91 8505975050</p>
                    <p className="text-sm text-gray-500">All days of the week (Except National Holidays) from 10 AM to 6 PM IST</p>
                  </div>

                  {/* <div className="bg-white p-4 rounded-lg shadow-md">
                    <div className="flex gap-4 mb-2">
                      <img src={housingimg} alt="Housing" className="w-8 h-8" />
                    </div>
                    <p className="font-medium">Housing Finance</p>
                    <p className="text-gray-600">customercare@ltfs.com | 1800-268-0000</p>
                    <p className="text-sm text-gray-500">All days of the week (Except National Holidays) from 9 AM to 6 PM IST</p>
                  </div>

                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <div className="flex gap-4 mb-2">
                      <img src={microloanimg} alt="Micro Loans" className="w-8 h-8" />
                    </div>
                    <p className="font-medium">Micro Loans | Rural LAP</p>
                    <p className="text-gray-600">customercare@ltfs.com | 1800-268-0000</p>
                    <p className="text-sm text-gray-500">All days of the week (Except National Holidays) from 9 AM to 6 PM IST</p>
                  </div> */}
                </div>
              </div>
            </div></motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <section className="bg-white">
      <div className="ps-3 pe-3 pb-5">
        {/* Header */}
        <div className="pt-8 pb-12">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-center px-4 max-w-3xl mx-auto"
          >
            <div className="heading-wrap relative">
              <h2 className="title-inner text-3xl md:text-4xl font-bold relative inline-block">
                At Your <span className="text-[#00b7ff]">Service.</span>
              </h2>
              <p className="text-gray-600 mt-3 text-sm md:text-base">
              Reach Blubucks on any device, anytime, from anywhere.
              </p>
            </div>
          </motion.div>
        </div>

        {/* Navigation Menu */}
        <div className="bg-[#4a4f6c] overflow-x-auto mx-auto max-w-6xl rounded-t-2xl">
          <div className="relative">
            <div
              ref={sliderRef}
              className="flex whitespace-nowrap transition-transform duration-300 ease-in-out"
              style={{
                transform: `translateX(-${currentSlide * 100}%)`,
              }}
            >
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  onClick={() => setActiveTab(index)}
                  className={`flex items-center gap-2 px-6 py-4 text-white hover:bg-[#5a5f7c] hover:border-b-2 hover:border-[var(--lightblue-color)]  relative mx-auto
               ${index === activeTab ? "" : ""}`}
                >
                  <span className={`${index === activeTab ? "text-[var(--lightblue-color)]" : ""}`}>
                    {item.icon}
                  </span>
                  <span
                    className={`text-sm font-medium ${index === activeTab ? "text-[var(--lightblue-color)]" : ""
                      }`}
                  >
                    {item.text}
                  </span>
                </button>
              ))}
            </div>


          </div>
        </div>

        {/* Tab Content */}
        <div className="bg-white w-full border md:w-[72rem] mx-auto " style={{
          borderBottomLeftRadius:'10px',
          borderBottomRightRadius:'10px',
        }}>
          {activeTab === 0 ? (
            <div className="px-4 py-8 md:py-12">
              <div className="max-w-6xl mx-auto">
                <div className="grid md:grid-cols-2 gap-8 md:gap-12">
                  {/* SVG Illustration */}
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="order-2 md:order-1"
                  >
                    <div className="flex justify-center items-center h-full">
                      <img
                        src={leftImage}
                        alt="Customer service illustration"
                        className="w-full"
                      />
                    </div>
                  </motion.div>

                  {/* Form */}
                  <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="order-1 md:order-2"
                  >
                     <RegisterFrom label={false} title={"Want a callback? Enter your details."} />
                    {/* <div className="bg-white p-6 md:p-8 rounded-2xl shadow-lg">
                      <h2 className="text-2xl font-semibold mb-6 text-[#4a4f6c]">
                        Want a callback? Enter your details.
                      </h2>
                      <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
                        <input
                          type="text"
                          placeholder="Enter your name"
                          className="w-full p-4 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#00b7ff] transition-all text-base"
                          required
                        />
                        <input
                          type="tel"
                          placeholder="Mobile No."
                          className="w-full p-4 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#00b7ff] transition-all text-base"
                          required
                        />
                        <div className="relative">
                          <select
                            className="w-full p-4 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#00b7ff] transition-all appearance-none bg-white text-base text-gray-500"
                            required
                          >
                            <option value="">Select Product</option>
                            <option value="1">Personal Loan</option>
                            <option value="2">Business Loan</option>
                            <option value="3">Home Loan</option>
                            <option value="4">Vehicle Loan</option>
                          </select>
                          <div className="absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="text-xs leading-relaxed text-gray-500 mt-6">
                          By proceeding, I confirm that the information provided by me here is accurate. I authorize L&T Finance and/or its authorized representatives to contact me for any queries and/or my documents collection for the loan application. This will override registry on Do Not Disturb (DND)/National Do Not Call (NDNC)
                        </div>
                        <button type="submit" class="w-full  text-black font-semibold py-4 rounded-xl  transition-colors text-lg mt-6">
                          <a href="#_" class="relative rounded px-5 py-2.5 overflow-hidden group bg-yellow-500 hover:bg-gradient-to-r hover:from-yellow-500 hover:to-cyan-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300 w-full h-full flex items-center justify-center">
                            <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                            <span class="relative">GET A CALLBACK</span>
                          </a>
                        </button>

                      </form>
                    </div> */}
                  </motion.div>
                </div>
              </div>
            </div>
          ) : (
            renderTabContent()
          )}
        </div>
      </div>
    </section>
  );
}

