import { motion } from 'framer-motion'
import SIDBI from '../Images/SIDBI.png'
import SBI from '../Images/sbi.png'
import BankOfBaroda from '../Images/Bank_of_Baroda.png'
import PNB from '../Images/punjab_obc_united.png'
import IndianBank from '../Images/idbi.png'
import BankOfIndia from '../Images/boi.png'
import BankOfMaharashtra from '../Images/bom.png'
import CanaraBank from '../Images/canara_bank_new.png'
import CentralBankOfIndia from '../Images/centralbankofindia.png'
import IDBIBank from '../Images/idbi.png'
import IndianOverseasBank from '../Images/indianoversis.png'
import PunjabAndSindBank from '../Images/psb.png'
import UCOBank from '../Images/uco_bank.png'
import UnionBank from '../Images/union_bank.png'
import IDFCFirstBank from '../Images/idfc-first-bank.png'
import ICICIBank from '../Images/icici.png'
import YesBank from '../Images/yes_bank.png'
import FederalBank from '../Images/federal_bank_new.png'

const banks = [
  { name: 'SIDBI', logo: SIDBI },
  { name: 'SBI', logo: SBI },
  { name: 'Bank of Baroda', logo: BankOfBaroda },
  { name: 'PNB', logo: PNB },
  { name: 'Indian Bank', logo: IndianBank },
  { name: 'Bank of India', logo: BankOfIndia },
  { name: 'Bank of Maharashtra', logo: BankOfMaharashtra },
  { name: 'Canara Bank', logo: CanaraBank },
  { name: 'Central Bank of India', logo: CentralBankOfIndia },
  { name: 'IDBI Bank', logo: IDBIBank },
  { name: 'Indian Overseas Bank', logo: IndianOverseasBank },
  { name: 'Punjab & Sind Bank', logo: PunjabAndSindBank },
  { name: 'UCO Bank', logo: UCOBank },
  { name: 'Union Bank', logo: UnionBank },
  { name: 'IDFC First Bank', logo: IDFCFirstBank },
  { name: 'ICICI Bank', logo: ICICIBank },
  { name: 'Yes Bank', logo: YesBank },
  { name: 'Federal Bank', logo: FederalBank },
]

const PartnerBanks = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-white to-[#00c8f8]">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold text-cyan-800 mb-2">Partner Banks</h2>
          <div className="w-24 h-1 bg-cyan-500 mx-auto"></div>
        </motion.div>
        
        <motion.div 
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {banks.map((bank, index) => (
            <motion.div
              key={bank.name}
              className="bg-white rounded-lg shadow-lg p-4 hover:shadow-xl transition-all duration-300 hover:scale-105"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <img
                src={bank.logo}
                alt={`${bank.name} logo`}
                className="w-full h-20 object-contain"
              />
              <p className="mt-2 text-center text-sm text-cyan-700 font-medium">{bank.name}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}

export default PartnerBanks

